var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-col", { staticClass: "col-sm-12" }, [
    _c(
      "div",
      [
        _c("b-form-input", {
          attrs: { type: "range", min: _vm.minValue, max: "500" },
          model: {
            value: _vm.paymentValue,
            callback: function ($$v) {
              _vm.paymentValue = $$v
            },
            expression: "paymentValue",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }